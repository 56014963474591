<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import { generateRandomString } from "@/utils/util.js";
export default {
	created() {
		const user_id = localStorage.getItem("user_id") || generateRandomString();
		this.$store.commit("USER_SET", {
			user_id
		})
		localStorage.setItem("user_id", user_id)
	}
}
</script>