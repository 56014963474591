<template>
  <div class="foot-box">
    <dl v-if="plat_info && plat_info.link && plat_info.link.length">
      <dt>友情链接</dt>
      <dd>
        <a v-for="item in plat_info.link" :key="item.name" :href="item.url" target="_blank">
          {{ item.name }}
        </a>
      </dd>
    </dl>
    <p>Copyright © 2017-2024 达奇 AI. All Rights Reserved 冀ICP备17019485号-1</p>
    <!-- <p>[{{ type }}]</p> -->
  </div>
</template>

<script>
import { checkDevice } from "@/utils/util.js";
export default {
  data() {
    return {
      plat_info: null,
      type: ''
    }
  },
  created() {
    this.get_plat_info()
    this.type = checkDevice();
  },
  methods: {
    get_plat_info() {
      this.$request
        .get('api/paper/get/tongji')
        .then(res => {
          this.plat_info = res.data;
        })
    },
  }
}
</script>