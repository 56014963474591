import Vue from 'vue'
import VueRouter from 'vue-router'
import {
	Message,
	MessageBox
} from 'element-ui'
Vue.use(VueRouter)
// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
// {
// 		path: "/",
// 		name: "登录页",
// 		component: () => import('@/views/Login/index.vue')
// 	},
const routes=[
	{
		path:"/",
		name:"home",
		component: () => import('@/views/home.vue')
	}
]

let router = new VueRouter({
	routes,
	mode: "history",
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
});
router.beforeEach((to, form, next) => {
	next()
})

export default router