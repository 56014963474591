import Vue from 'vue';
import axios from "axios";
import { Message } from 'element-ui';
axios.defaults.baseURL = 'https://www.daqeesoft.com'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("user_id");
axios.defaults.timeout = 60 * 1000;
const request = axios.create();
request.interceptors.response.use(res => {
	if (res.data.code != 200 && res.data.message !== "Server Error") {
		Message.error(res.data.message)
	}
	return res.data
});
request.interceptors.request.use(config => {
	if (!config.data) {
		config.data = {}
	}
	config.data.user_id = localStorage.getItem("user_id")
	return config
}, error => {
	Message.error(error)
	Promise.reject(error)
})
Vue.prototype.$request = request;