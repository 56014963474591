// 生成随机串
export const generateRandomString = () => {
	const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	let result = 'DQ';
	const timestamp = new Date().toISOString().replace(/[-:.]/g, '').replace('T', '');
	result += timestamp;

	for (let i = 0; i < 9; i++) {
		const randomIndex = Math.floor(Math.random() * randomChars.length);
		result += randomChars.charAt(randomIndex);
	}

	return result;
};

// 设备环境检查
export const checkDevice = () => {
	let type = null
	let isMobile = /Mobi|Android/i.test(navigator.userAgent);
	let isWechat = navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
	if (isMobile) {
		type = isWechat ? 'wx' : 'h5';
	} else {
		type = 'pc';
	}
	return type;
}

// 微信环境下 登录信息获取
export async function setWxInfo(that) {
	let user_id = that.$store.state.user_id
	if (checkDevice() !== 'wx') return;
	if (that.$route.query.source && that.$route.query.source !== '') {
		sessionStorage.setItem("source", that.$route.query.source)
	} else {
		sessionStorage.setItem("source", null)
	}
	if (that.$route.query.code) {
		let res = await that.$request.post('/wechat/auth/getauthinfo', {
			code: that.$route.query.code,
			paper_userid: user_id
		})
		if (res.code == 200) {
			user_id = res.data.puser_id
			that.$store.commit("USER_SET", { user_id })
			localStorage.setItem("user_id", user_id)
		} else {
			jumpPage();
		}
		return;
	}
	jumpPage();
}

function jumpPage() {
	let appId = 'wx2483ef69f2649f97';
	let baseUrl = 'https://www.daqeesoft.com'
	let uri = baseUrl + '/wechat/auth/callback?redirect_uri=' + baseUrl + '/?source=' + sessionStorage.getItem("source")
	let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId +
		'&redirect_uri=' + encodeURIComponent(uri) +
		'&response_type=code&scope=snsapi_userinfo';
	location.href = url
}