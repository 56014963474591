<template>
  <div class="header" id="header" :class="scroll_top > 80 ? 'shadow' : ''">
    <div class="common_view">
      <div class="left">
        <img src="@/assets/images/02.png" class="logo" alt="" srcset="">
        <p class="p1">达奇AI</p>
        <p class="p2">论文写作平台</p>
      </div>
      <div class="right" v-if="plat_info">
        <p class="p1">今日访客 {{ plat_info.outlineCount }}，累计{{ plat_info.paperCount }}个用户生成了参考论文</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    plat_info: {}
  },
  data() {
    return {
      header_height: 0,
      scroll_top: 0,
    }
  },
  mounted() {
    this.get_height()
    window.onscroll = () => {
      var scroll_top = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scroll_top = document.documentElement.scrollTop;
      }
      this.scroll_top = scroll_top
    }
  },
  updated() {
    this.get_height()
  },
  methods: {
    get_height() {
      var element = document.getElementById("header");
      var height = element.offsetHeight;
      this.header_height = height;
      this.$emit("get_height", this.header_height)
    }
  }
}
</script>

<style lang="scss">
.shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 14px 0px;
  transition: all 0.2s ease 0s;
}

.header {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 58px;
  left: 0;
  top: 0;
  z-index: 900;
  overflow: auto;
  transition: all 0.2s ease 0s;

  .common_view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .right {
      .p1 {
        font-size: 12px;
        font-family: HarmonyOS Sans, HarmonyOS Sans;
        font-weight: 400;
        color: #101C3D;
      }
    }

    .left {
      display: flex;
      align-items: center;

      .logo {
        width: 42px;
        height: 42px;
      }

      .p1 {
        font-size: 18px;
        font-family: Helvetica, Helvetica;
        font-weight: 700;
        color: #101C3D;
        margin-left: 10px;
      }

      .p2 {
        font-size: 12px;
        font-family: HarmonyOS Sans, HarmonyOS Sans;
        font-weight: 400;
        color: #101C3D;
        margin-left: 10px;
      }
    }
  }
}
</style>